const messages = {
  title: 'Admin',
  companyName: 'AJ Homes',
  home: 'Home',
  about: 'About',
  properties: 'Properties',
  testimonials: 'Testimonials',
  contact: 'Contact Us'
};

export default messages;
